// ==============================
// Override Variables
// 覆盖变量
// ==============================

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,500;0,700;1,400;1,500&display=swap');

$global-font-family: Raleway, Source Code Pro, Menlo, Consolas, Monaco, monospace;
$global-font-size: 18px;
$global-font-weight: 500;
$global-line-height: 1.6rem;
